import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"faq"} />
		<Helmet>
			<title>
				FAQ | Часті запитання в Blanfer Studio
			</title>
			<meta name={"description"} content={"Ласкаво просимо на сторінку поширених запитань Blanfer Studio, де ми прагнемо відповісти на ваші запитання та занепокоєння з ясністю і точністю. "} />
			<meta property={"og:title"} content={"FAQ | Часті запитання в Blanfer Studio"} />
			<meta property={"og:description"} content={"Ласкаво просимо на сторінку поширених запитань Blanfer Studio, де ми прагнемо відповісти на ваші запитання та занепокоєння з ясністю і точністю. "} />
			<meta property={"og:image"} content={"https://blanfer-ua.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://blanfer-ua.com/img/1839979.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://blanfer-ua.com/img/1839979.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://blanfer-ua.com/img/1839979.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://blanfer-ua.com/img/1839979.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://blanfer-ua.com/img/1839979.png"} />
			<meta name={"msapplication-TileImage"} content={"https://blanfer-ua.com/img/1839979.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="100px 0 100px 0" background="--color-darkL2" quarkly-title="FAQ-4">
			<Text margin="0px 0px 15px 0px" font="normal 600 42px/1.2 --fontFamily-sans" color="--light">
				FAQ
			</Text>
			<Text margin="0px 0px 70px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="#b2bac1" sm-margin="0px 0px 50px 0px">
				Ласкаво просимо на сторінку поширених запитань Blanfer Studio, де ми прагнемо відповісти на ваші запитання та занепокоєння з ясністю і точністю. Наша мета - надати вам миттєву інформацію про наші послуги та роботу студії.
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="50px 50px"
				md-grid-template-columns="1fr"
				sm-grid-gap="35px 0"
			>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						Які види фотопослуг ви пропонуєте?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#b2bac1">
						Ми спеціалізуємося на індивідуальних та групових портретах, висвітленні подій, фотозйомці продуктів та професійних майстер-класах.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						Чи потрібно бронювати заздалегідь?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#b2bac1">
						Так, бронювання заздалегідь необхідне, щоб забезпечити доступність студії і дозволити нам адаптувати вашу сесію до ваших потреб.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						Скільки часу триває типова фотосесія?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#b2bac1">
						Тривалість зйомки варіюється в залежності від проекту. Індивідуальні портрети можуть зайняти від 1 до 2 годин, тоді як події та групові зйомки триватимуть довше.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						Чи можна провести фотосесію на природі?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#b2bac1">
						Безумовно! Ми любимо природне світло і з радістю влаштуємо сесію на природі.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						Що одягнути на фотосесію?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#b2bac1">
						Одягайте те, що ви відчуваєте! Якщо ви не впевнені, ми можемо проконсультувати щодо вибору гардеробу, який доповнить тематику та тон вашої зйомки.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						Коли я отримаю свої фотографії?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#b2bac1">
						Зазвичай термін отримання фінальних зображень становить від 2 до 4 тижнів, залежно від складності необхідного редагування.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});